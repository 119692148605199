// const API_ENDPOINT = 'http://company.unitedsodas.test/_voteplan'
const API_ENDPOINT = 'https://usoa-company.clients.house/_voteplan'

const api = {
  submit: async (data) => {
    const res = await fetch(`${API_ENDPOINT}/submit`, {
      method: 'post',
      body: data,
    })

    return await res.json()
  },
  calendar: async (data) => {
    const res = await fetch(`${API_ENDPOINT}/calendar`, {
      method: 'post',
      body: data,
    })

    return await res.json()
  },
  gallery:  async () => {
    const res = await fetch(`${API_ENDPOINT}/gallery`)
    return await res.json()
  },
  ticker:  async () => {
    const res = await fetch(`${API_ENDPOINT}/ticker`)
    return await res.json()
  }
}

export default api
