import React, { useRef, useState, useEffect, useCallback } from 'react'
import Flickity from 'flickity'
import 'flickity-imagesloaded'
import api from './common/api'

const tickerSpeed = 1

function Gallery() {
  const [slides, setSlides] = useState([])
  const flickity = useRef(null)

  useEffect(() => {
    const init = async () => {
      const res = await api.gallery()

      setSlides(res.items)
    }

    init()
  }, [])

  const update = useCallback(() => {
    const slider = flickity.current
    if (slider.slides) {
      slider.x = (slider.x - tickerSpeed) % slider.slideableWidth
      slider.selectedIndex = slider.dragEndRestingSelect()
      slider.updateSelectedSlide()
      slider.settle(slider.x)
    }

    window.requestAnimationFrame(update)
  }, [])

  useEffect(() => {
    if (slides.length > 0) {
      flickity.current = new Flickity('.section-gallery', {
        autoPlay: false,
        prevNextButtons: false,
        pageDots: false,
        draggable: true,
        wrapAround: true,
        imagesLoaded: true,
        selectedAttraction: 0.015,
        friction: 0.25
      })

      update()
    }
  }, [update, slides])

  return (
    <section className="section-gallery page-section">
      {slides.map((url) => (
        <div className="slide" key={url}>
          <img src={url} alt="Vote Plan" />
        </div>
      ))}
    </section>
  )
}

export default Gallery
