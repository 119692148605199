import React from 'react'

function Content() {
  return (
    <section className="section-content page-section">
      <div className="grid-container">
        <p>Studies show that making a Voting Plan doubles the likelihood of voting.</p>
        <p>What happens when you share that plan? Even thinking of a plan makes someone 10% more likely to vote.</p>
        <p>MAKE A PLAN. SHARE A PLAN. LET’S VOTE.</p>
        <p><small>Source</small></p>
      </div>
    </section>
  )
}

export default Content
