import React, { useState, useEffect } from 'react'
import format from 'date-fns/format'
import { useFormState } from './FormContext'
import api from './common/api'

function FormActions() {
  const [actionsEnabled, setActionsEnabled] = useState(false)
  const { name, method, date, time, county, canvas, doSubmit } = useFormState()

  useEffect(() => {
    const empty = [name, method, date, time, county].some(x => x === '' || x === null)

    setActionsEnabled(!empty)
  }, [name, method, date, time, county])

  const handleSubmit = async () => {
    setActionsEnabled(false)

    const image = canvas.toDataURL('image/png', 1.0).replace('image/png', 'image/octet-stream')
    const link = document.createElement('a')
    link.download = 'vote-plan.png'
    link.href = image
    link.click()

    if (doSubmit) {
      const submissionImage = await new Promise((resolve) => {
        return canvas.toBlob(resolve)
      })

      const data = new FormData()
      data.append('name', name)
      data.append('method', method)
      data.append('date', format(date, 'yyyy-MM-dd'))
      data.append('time', format(time, 'hh:mm a'))
      data.append('county', county)
      data.append('image', submissionImage)

      await api.submit(data)
    }

    setActionsEnabled(true)
  }

  const handleAddToCalendar = async () => {
    setActionsEnabled(false)

    const data = new FormData()
    data.append('name', name)
    data.append('method', method)
    data.append('date', format(date, 'yyyy-MM-dd'))
    data.append('time', format(time, 'hh:mm a'))
    data.append('county', county)
    data.append('datetime', (new Date(`${format(date, 'yyyy-MM-dd')} ${format(time, 'hh:mm:00')}`).toISOString()))
    data.append('tz', Intl.DateTimeFormat().resolvedOptions().timeZone)

    const res = await api.calendar(data)

    const link = document.createElement('a')
    link.download = 'vote-plan.ics'
    link.href = res.result
    link.click()

    setActionsEnabled(true)
  }

  return (
    <div className="generator-actions">
      <button type="button" className="btn-primary" onClick={handleSubmit} disabled={!actionsEnabled}>Download</button>
      <button type="button" className="btn-secondary" onClick={handleAddToCalendar} disabled={!actionsEnabled}>Add To My Cal</button>
    </div>
  )
}

export default FormActions
