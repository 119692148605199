import React from 'react'

function CallToAction() {
  return (
    <section className="section-cta page-section">
      <div className="grid-container">
        <h2>Need help making a plan?</h2>

        <div className="cta-columns">
          <div className="cta-column">
            <div>
              <h3>This all sounds neat but I need more information.</h3>
              <p>Go visit The Voting School! They have everything you will need.</p>
            </div>

            <div className="cta-column__actions">
              <a href="http://google.com" className="btn" target="_blank" rel="noopener noreferrer">Visit Voting School</a>
            </div>
          </div>

          <div className="cta-column">
            <div>
              <h3>Cool! but I don’t have time - can you remind me?</h3>
              <p>Visit vote.org to get election reminders.</p>
            </div>

            <div className="cta-column__actions">
              <a href="https://vote.org" className="btn" target="_blank" rel="noopener noreferrer">Visit Vote.org</a>
            </div>
          </div>

          <div className="cta-column">
            <div>
              <h3>I care about the election but I'm not a citizen. Anything I can do to help?</h3>
              <p>Check out allies for america</p>
            </div>

            <div className="cta-column__actions">
              <a href="https://alliesforamerica.us/" className="btn" target="_blank" rel="noopener noreferrer">Visit Allies for America</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CallToAction
