import React, { useState, useEffect } from 'react'
import Marquee3k from 'marquee3000'
import api from './common/api'

function getRandomColor() {
  const colors = [
    '#ffc600',
    '#ff8672',
    '#dcc7b7',
    '#006098',
    '#752e4a',
    '#ddb5c8',
    '#ffd720',
    '#93d500',
    '#f4436c',
    '#ff8300',
    '#4a9462',
    '#db0032'
  ]

  return colors[Math.floor(Math.random() * colors.length)]
}

function shuffleArray(arr) {
  const array = [...arr]

  let i = array.length - 1
  for (; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    const temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }

  return array
}

function Ticker() {
  const [messages, setMessages] = useState([])

  useEffect(() => {
    const init = async () => {
      const res = await api.ticker()

      setMessages(res.items)
    }

    init()
  }, [])

  useEffect(() => {
    if (messages.length > 0) {
      Marquee3k.init()
    }
  }, [messages])

  return (
    <section className="section-ticker page-section">
      <div className="marquee3k ticker ticker--first" data-speed="1">
        <div>
          {shuffleArray(messages).map((message) => (
            <span className="ticker-item" key={message.id} style={{ color: `${getRandomColor()}` }}>
              {message.message}
            </span>
          ))}
        </div>
      </div>

      <div className="marquee3k ticker ticker--second" data-speed="1" data-reverse="true">
        <div>
          {shuffleArray(messages).map((message) => (
            <span className="ticker-item" key={message.id} style={{ color: `${getRandomColor()}` }}>
              {message.message}
            </span>
          ))}
        </div>
      </div>

      <div className="marquee3k ticker ticker--third" data-speed="1">
        <div>
          {shuffleArray(messages).map((message) => (
            <span className="ticker-item" key={message.id} style={{ color: `${getRandomColor()}` }}>
              {message.message}
            </span>
          ))}
        </div>
      </div>

      <div className="marquee3k ticker ticker--fourth" data-speed="1" data-reverse="true">
        <div>
          {shuffleArray(messages).map((message) => (
            <span className="ticker-item" key={message.id} style={{ color: `${getRandomColor()}` }}>
              {message.message}
            </span>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Ticker
