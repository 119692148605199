import React from 'react'
import './App.scss'
import { FormProvider } from './FormContext'
import Gallery from './Gallery'
import Content from './Content'
import Form from './Form'
import FormActions from './FormActions'
import Preview from './Preview'
import Ticker from './Ticker'
import CallToAction from './CallToAction'

function App() {
  return (
    <>
      <Gallery />
      <Content />
      <FormProvider>
        <section className="section-vote page-section">
          <div className="grid-container">
            <h2>Make your voting plan here.</h2>

            <div className="generator-container">
              <div className="result-wrapper">
                <Preview />
              </div>

              <div className="form-wrapper">
                <Form />
              </div>
            </div>

            <FormActions />
          </div>
        </section>
      </FormProvider>
      <Ticker />
      <CallToAction />
    </>
  )
}

export default App
