import React, { useEffect, useRef } from 'react'
import 'date-fns'
import easydropdown from 'easydropdown'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  DatePicker,
  TimePicker,
} from '@material-ui/pickers'
import { useFormState, useFormDispatch } from './FormContext'

const methodOptions = [
  {
    label: 'In Person',
    value: 'In Person'
  },
  {
    label: 'By Mail',
    value: 'By Mail'
  },
]

function Select({ options, handleChange }) {
  const el = useRef(null)

  useEffect(() => {
    if (el.current) {
      easydropdown(el.current, {
        callbacks: {
          onSelect: nextValue => handleChange(nextValue)
        }
      })
    }
  }, [el, handleChange])

  return (
    <select ref={el}>
      <option value="" data-placeholder>Method</option>

      {options.map(option => <option key={option.value} value={option.value}>{option.label}</option>)}
    </select>
  )
}

function Form() {
  const { name, method, date, time, county, doSubmit } = useFormState()
  const dispatch = useFormDispatch()

  const handleNameChange = (e) => {
    dispatch({ type: 'setName', payload: e.target.value })
  }

  const handleMethodChange = (method) => {
    dispatch({ type: 'setMethod', payload: method })

    if (method === 'In Person') {
      dispatch({ type: 'setDate', payload: new Date(2020, 10, 3) })
    } else {
      dispatch({ type: 'setDate', payload: null })
    }
  }

  const handleDateChange = (date) => {
    dispatch({ type: 'setDate', payload: date })
  }

  const handleTimeChange = (time) => {
    dispatch({ type: 'setTime', payload: time })
  }

  const handleCountyChange = (e) => {
    dispatch({ type: 'setCounty', payload: e.target.value })
  }

  const handleDoSubmitChange = (e) => {
    dispatch({ type: 'setDoSubmit', payload: e.target.checked })
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <form>
        <div className={`form-field form-field--name ${name !== '' && 'filled'}`}>
          <span>
            <svg viewBox="0 0 19 19">
              <path d="M0 14.252v3.75h3.75l11.06-11.06-3.75-3.75L0 14.253zm17.71-10.21a.996.996 0 000-1.41L15.37.292a.996.996 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" fill="#D8D8D8"/>
            </svg>
          </span>

          <input type="text" placeholder="First Name" value={name} onChange={handleNameChange} maxLength="8" />
        </div>

        <div className={`form-field form-field--method styled-dropdown ${method !== '' && 'filled'}`}>
          <span>
            <svg viewBox="0 0 20 19">
              <path d="M19.99 7c0-.72-.37-1.35-.94-1.7L10 0 .95 5.3C.38 5.65 0 6.28 0 7v10c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2l-.01-10zM10 12L1.74 6.84 10 2l8.26 4.84L10 12z" fill="#D8D8D8"/>
            </svg>
          </span>

          <Select options={methodOptions} handleChange={handleMethodChange} />
        </div>

        <div className={`form-field form-field--date ${date && 'filled'}`}>
          <span>
            <svg viewBox="0 0 20 22">
              <path d="M18 2h-1V0h-2v2H5V0H3v2H2C.9 2 0 2.9 0 4v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 18H2V7h16v13z" fill="#D8D8D8"/>
            </svg>
          </span>

          <DatePicker
            autoOk
            emptyLabel="Date"
            disablePast
            format="MMM. d"
            value={date}
            onChange={handleDateChange}
            shouldDisableDate={(x) => x > new Date(2020, 10, 3)}
          />
        </div>

        {method !== 'By Mail' && (
          <div className={`form-field form-field--time ${time && 'filled'}`}>
            <span>
              <svg viewBox="0 0 20 20">
                <path d="M9.99 0C4.47 0 0 4.48 0 10s4.47 10 9.99 10C15.52 20 20 15.52 20 10S15.52 0 9.99 0zM10 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" fill="#D8D8D8"/>
                <path d="M10.5 5H9v6l5.25 3.15.75-1.23-4.5-2.67V5z" fill="#D8D8D8"/>
              </svg>
            </span>

            <TimePicker
              autoOk
              emptyLabel="Time"
              format="hh:mm a"
              minutesStep={15}
              initialFocusedDate={new Date(2020, 10, 3, 9, 0, 0)}
              value={time}
              onChange={handleTimeChange}
            />
          </div>
        )}

        <div className={`form-field form-field--county ${county !== '' && 'filled'}`}>
          <span>
            <svg viewBox="0 0 14 20">
              <path d="M13 6c0-3.31-2.69-6-6-6S1 2.69 1 6c0 4.5 6 11 6 11s6-6.5 6-11zM5 6c0-1.1.9-2 2-2s2 .9 2 2a2 2 0 11-4 0zM0 18v2h14v-2H0z" fill="#D8D8D8"/>
            </svg>
          </span>

          <input type="text" placeholder="County or City" value={county} onChange={handleCountyChange} maxLength="20" />
        </div>

        <div className="form-checkbox">
          <input id="doSubmit" type="checkbox" checked={doSubmit} onChange={handleDoSubmitChange} />

          <label htmlFor="doSubmit">
            <div>
              <div className="form-checkbox-label--primary">
                Yes, add my plan to the collection.
              </div>
              <div className="form-checkbox-label--secondary">(Don’t worry, we take privacy seriously never save any personal information other.)</div>
            </div>
          </label>
        </div>
      </form>
    </MuiPickersUtilsProvider>
  )
}

export default Form
