import React from 'react'
import ReactDOM from 'react-dom'
import 'normalize.css'
import './styles/index.scss'
import App from './App'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

document.querySelector('.site-menu-btn').addEventListener('click', () => {
  document.documentElement.classList.toggle('js-menu-opened')
})
